import React from 'react';
import PropTypes from 'prop-types';

const StaticPage = ({ frontmatter, children, lang }) => {
	return (
		<article className={'pa-TextContent_Container'}>
			<h1>{frontmatter.title}</h1>
			<div>{React.cloneElement(children, { lang })}</div>
		</article>
	);
};

StaticPage.propTypes = {
	frontmatter: PropTypes.object,
	children: PropTypes.element,
	lang: PropTypes.string.isRequired,
};

export default StaticPage;
